import * as React from 'react';
import {useEffect, useState} from 'react';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import MenuItem from '@mui/material/MenuItem';
import {NavLink, useLocation} from "react-router-dom";
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import s from './header.module.css'
import {
    helloTitle,
    indexPage,
    powerOfTechPage,
    powerOfTechTitle,
    studentsInteractPage,
    studentsInteractTitle
} from "../../config";

export const Header = () => {
    const location = useLocation();

    const [anchorElNav, setAnchorElNav] = useState(null);
    const [currentPage, setCurrentPage] = useState('')
    const handleOpenNavMenu = (e) => setAnchorElNav(e.currentTarget);
    const handleCloseNavMenu = () => setAnchorElNav(null);

    const makeStyle = (isActive) => {
        return {
            border: isActive && '2px solid white',
            borderRadius: '10%'
        };
    }

    useEffect(() => {
        let pageName;

        if (location.pathname === indexPage) {
            pageName = helloTitle;
        } else if (location.pathname === powerOfTechPage) {
            pageName = powerOfTechTitle;
        } else if (location.pathname === studentsInteractPage) {
            pageName = studentsInteractTitle;
        }

        setCurrentPage(pageName)
    }, [])


    return (
        <Box sx={{bgcolor: '#f2f2f2'}}>
            <Container maxWidth='sm'>
                <Toolbar disableGutters sx={{
                    display: 'flex',
                    justifyContent: 'space-between'
                }}>
                    <Box sx={{flexGrow: 1, display: {xs: "flex", sm: "none"}, justifyContent: 'space-between'}}>
                        <IconButton
                            size="large"
                            aria-label="account of current user"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            onClick={handleOpenNavMenu}
                            color="inherit"
                        >
                            <MenuIcon/>
                        </IconButton>
                        <Menu
                            id="menu-appbar"
                            anchorEl={anchorElNav}
                            anchorOrigin={{
                                vertical: "bottom",
                                horizontal: "left"
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: "top",
                                horizontal: "left"
                            }}
                            open={Boolean(anchorElNav)}
                            onClose={handleCloseNavMenu}
                            sx={{display: {xs: "block", sm: "none"}}}
                        >

                            <NavLink to={indexPage} className={s.link} onClick={() => setCurrentPage(helloTitle)}>
                                <MenuItem onClick={handleCloseNavMenu}>{helloTitle}</MenuItem>
                            </NavLink>

                            <NavLink to={powerOfTechPage} className={s.link}
                                     onClick={() => setCurrentPage(powerOfTechTitle)}>
                                <MenuItem onClick={handleCloseNavMenu}>{powerOfTechTitle}</MenuItem>
                            </NavLink>

                            <NavLink to={studentsInteractPage} className={s.link}
                                     onClick={() => setCurrentPage(studentsInteractTitle)}>
                                <MenuItem onClick={handleCloseNavMenu}>{studentsInteractTitle}</MenuItem>
                            </NavLink>
                        </Menu>
                        <MenuItem>{currentPage}</MenuItem>
                    </Box>

                    <Box sx={{flexGrow: 1, display: {xs: "none", sm: "flex"}}}>

                        <NavLink to={indexPage} className={s.link}>
                            {({isActive}) => <MenuItem sx={makeStyle(isActive)}>{helloTitle}</MenuItem>}
                        </NavLink>

                        <NavLink to={powerOfTechPage} className={s.link}>
                            {({isActive}) => <MenuItem sx={makeStyle(isActive)}>{powerOfTechTitle}</MenuItem>}
                        </NavLink>

                        <NavLink to={studentsInteractPage} className={s.link}>
                            {({isActive}) => <MenuItem sx={makeStyle(isActive)}>{studentsInteractTitle}</MenuItem>}
                        </NavLink>
                    </Box>
                </Toolbar>
            </Container>
        </Box>
    );
}
