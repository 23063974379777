import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import GitHubIcon from '@mui/icons-material/GitHub';
import TelegramIcon from '@mui/icons-material/Telegram';
import {Link, NavLink} from "react-router-dom";
import s from './footer.module.css';
import {gitHubLink, tgLink} from "../../config";

export const Footer = () =>  {
    const footerStyleBox = {mt: 'auto', bgcolor: '#f2f2f2'};

    const footerStyleContainer = {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        minHeight: 64,
    };

    return (
        <Box component="footer" sx={footerStyleBox}>
            <Container maxWidth="sm" sx={footerStyleContainer}>
                <Typography variant="body1">
                    {'© '}
                    <NavLink href="/" className={s.link}>
                        Сергей Железников
                    </NavLink>
                    {' '}{new Date().getFullYear()}
                </Typography>

                <div>
                    <Link to={gitHubLink} target="_blank" className={`${s.link} ${s.link_github}`}>
                        <GitHubIcon/>
                    </Link>
                    <Link to={tgLink} target="_blank" className={s.link}>
                        <TelegramIcon/>
                    </Link>
                </div>
            </Container>
        </Box>
    );
}