import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import React from "react";

export const NotFound = () => {
    const headerStyle = {
        pt: 3,
        textAlign: 'center'
    };

    return (
        <Container maxWidth={'sm'}>
            <Typography variant="h3" component="h3" sx={headerStyle} gutterBottom>
                Ой ой ой
            </Typography>
            <Typography variant="p" component="p" paragraph gutterBottom>
                Кажется такой страницы нет
            </Typography>
        </Container>
    )
}