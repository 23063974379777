/**
 * Pages
 */

export const indexPage = '/';
export const powerOfTechPage = '/power-of-technology';
export const studentsInteractPage = '/students-interaction';

export const helloTitle = 'привет';
export const powerOfTechTitle = 'сила технологий';
export const studentsInteractTitle = 'ответы студентам';

/**
 * Links
 */

export const tgLink = 'https://t.me/zheleznikov';
export const gitHubLink = 'https://github.com/zheleznikov/hello';
export const gitHubPrLink = 'https://github.com/zheleznikov/hello';
export const notionLink = 'https://zhele.notion.site/2c5296ea36b448ca878496f87687ccb4';