import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import React from "react";
import {Link} from "react-router-dom";
import {notionLink} from "../../config";

export const StudentsInteraction = () => {
    const linkStyle = {
        textDecoration: 'none',
        color: "#0079bf"
    };

    const headerStyle = {
        pt: 3,
        textAlign: 'center'
    };

    return (
        <Container maxWidth={'sm'}>
            <Typography variant="h3" component="h3" sx={headerStyle} gutterBottom>
                Задание 2
            </Typography>
            <Typography variant="p" component="p" paragraph gutterBottom>
                Ответы выложил&nbsp;
                <Link to={notionLink} style={linkStyle} target="_blank">на своей странице в ноушн</Link>.
            </Typography>
        </Container>
    )
}