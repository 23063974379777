import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import {EmbeddedVideo} from "../../components/embedded-video/embedded-video";
import React from "react";

export const PowerOfTechnology = () => {
    return (
        <Container maxWidth={'sm'}>
            <div>
                <Typography variant="h3" component="h3" sx={{pt: 3, textAlign: 'center'}} gutterBottom>Сила CSS</Typography>
                <EmbeddedVideo videoId='PxKZN9JgCpQ'
                               title="Пример использования CSS"/>
                    <Typography variant="p" component="p"  paragraph>
                        На видео рассказываю, какие CSS-свойства использовал, когда делал рекламный лендинг для наушников JBL.
                    </Typography>
                <Typography variant="p" component="p"  paragraph>
                    Задача состояла в том, чтобы создать у пользователя максимальный эффект присутствия - как будто видишь содержимое сайта из окна
                    иллюминатора. Для этого использовал свойства transition, animation, библиотеку для работы с parallax и различные эффекты при
                    наведении на элементы.
                </Typography>
                <Typography variant="p" component="p"  paragraph>
                   На мой взгляд, сайт получился, как и задумывалось, атмосферным. При этом использовал достаточно простые свойства, важно было внимательно подобрать настройки.
                </Typography>

            </div>


        </Container>
    )
}