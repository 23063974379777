import {Header} from "../header/header";
import {Footer} from "../footer/footer";
import {Route, Routes} from "react-router-dom";
import {Hello} from "../../pages/hello/hello";
import {PowerOfTechnology} from "../../pages/power-of-technology/power-of-technology";
import {StudentsInteraction} from "../../pages/students-interaction/students-interaction"
import Box from "@mui/material/Box";
import {powerOfTechPage, studentsInteractPage} from "../../config";
import {NotFound} from "../../pages/not-found/not-found";

export const App = () => {
    const boxStyles = {
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
        fontFamily: 'Roboto',
    }

    return (
        <Box sx={boxStyles}>
            <Header/>
            <Routes>
                <Route index element={<Hello/>}/>
                <Route path={powerOfTechPage} element={<PowerOfTechnology/>}/>
                <Route path={studentsInteractPage} element={<StudentsInteraction/>}/>
                <Route path='*' element={<NotFound/>}/>
            </Routes>
            <Footer/>
        </Box>
    );
}



