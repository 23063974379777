import {Box, Container, Typography} from "@mui/material";
import ava from '../../img/ava.jpg';
import React from "react";
import Avatar from "@mui/material/Avatar";
import {Link} from "react-router-dom";

import {gitHubPrLink} from "../../config";

export const Hello = () => {
    const textAndAvaBlockStyle = {
        display: 'flex',
        flexDirection: {
            xs: 'column',
            md: 'row'
        },
        alignItems: {
            xs: 'center'
        },
        pb: {
            xs: 0,
            md: 2
        }
    };

    const linkStyle = {
        textDecoration: 'none',
        color: "#0079bf"
    };

    return (
        <Container maxWidth="sm" >
            <Box >
                <Typography variant="h3" component="h3" sx={{pt: 3}} gutterBottom >
                    Привет, я Сергей
                </Typography>

                <Box sx={textAndAvaBlockStyle}>
                    <Avatar src={ava} sx={{width: 264, height: 264, mr: 3}} />
                    <div style={{paddingTop: 8}}>
                        <Typography variant="p" component="p" gutterBottom paragraph>
                            Я закончил школу в 2005, но с тех пор мои фото из школы куда-то пропали.
                            Поэтому просто расскажу немного о себе, своем карьерном пути и увлечениях.
                        </Typography>
                        <Typography variant="p" component="p" gutterBottom paragraph>
                            В 2020 году я закончил 7 кагорту Практикума по направлению «Веб-разработка».
                            Эти 9 месяцев добавили в мою жизнь смысла и, как позже выяснилось, значительно
                            повысили ее качество.
                        </Typography>
                    </div>
                </Box>

                <Typography variant="p" component="p" gutterBottom paragraph>
                    Чем я только не занимался: верстал лендинги,
                    был автоматизатором тестирования, стал тест-лидом, работал наставником в Практикуме на курсе QA+.
                    Потом понял, что тестирование не подходит мне по темпераменту.
                </Typography>

                <Typography variant="p" component="p" gutterBottom paragraph>
                    Сейчас работаю разработчиком в Сбере.
                    В основном мы делаем бэкенд, но единственный, кто знает фронтенд – это я.
                    Поэтому все задачи на пользовательский интерфейс – мои. JS – мой дар и проклятье.
                </Typography>

                <Typography variant="p" component="p" gutterBottom paragraph>
                    Кроме работы я люблю смотреть футбол, заниматься боксом и кроссфитом,
                    а еще когда-то хотел стать музыкантом и учился в Московском Колледже Импровизационной Музыки,
                    но устал от этого и женился.

                </Typography>
                <Typography variant="p" component="p" gutterBottom paragraph>
                    Разработка приложений – мое любимое занятие, потому что приносит деньги и держит мозг в тонусе. А
                    код этого сайта&nbsp;
                    <Link to={gitHubPrLink} style={linkStyle} target="_blank">можно посмотреть по ссылке</Link> или если кликнуть на иконку гитхаба в футере.
                </Typography>
            </Box>
        </Container>
    )
}